
const axios = require('axios')

export default {
  name: 'login-page',
  layout: 'server-render',
  transition(to, from) {
    if (!from) return 'fade'
    return +to.query.page > +from.query.page ? 'slide-right' : 'slide-left'
  },
  data() {
    return {
      isLoading: false,
      account: {
        email: '',
        password: ''
      },
      rules: {
        required: (value) => !!value || 'Required.',
        password: (value) => value && (value.length >= 6 || 'Min 6 characters'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      ressetEmail: null,
      isActive: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    loginWithGoogle() {
      this.$store.dispatch('loginWithGoogle').then(() => {
        this.$fireAuth.onAuthStateChanged(async (user) => {
          if (user) {
            const usersRef = await this.$fireStore.collection('clients').doc(user.uid)

            const client = await usersRef.get()
            if (client.exists) {
              const tokenResult = await this.$fireAuth.currentUser.getIdTokenResult()
              if (tokenResult.claims.admin) {
                this.$router.push({ path: '/dashboard/admin' })
              } else {
                this.$router.push({ name: 'dashboard', params: { id: user.uid } })
                localStorage.setItem('clientId', user.uid)
              }
            } else {
              this.$store.dispatch('logout')
              this.$snotify.error(
                "We couldn't find an account with the email you choose, please register first."
              )
              axios
                .post(`${process.env.API_URL}/deleteClientAccount`, {
                  userId: user.uid
                })
                .then((res) => {
                  // Unregistered user is deleted!
                  console.log(res.data)
                })
            }
          }
        })
      })
    },
    login(e) {
      e.preventDefault()
      this.isLoading = true
      this.$store
        .dispatch('login', this.account)
        .then(() => {
          this.$fireAuth.onAuthStateChanged(async (user) => {
            if (user) {
              this.$fireAnalytics.setUserId(user.uid)
              this.isLoading = false
              this.$store.commit('SET_USER', user.uid)
              const tokenResult = await this.$fireAuth.currentUser.getIdTokenResult()
              if (tokenResult.claims.admin) {
                this.$router.push({ path: '/dashboard/admin' })
              } else {
                this.$store.commit('SET_PROFILETAB', 0)
                localStorage.profileTab = 0
                localStorage.setItem('clientId', user.uid)
                this.$router.push({ name: 'dashboard', params: { id: user.uid } })
              }
            }
          })
        })
        .catch((error) => {
          this.isLoading = false
          if (error.code === 'auth/user-disabled') {
            this.$snotify.error('Your profile has been disabled. Contact us for more information.')
          } else if (error.code === 'auth/user-not-found') {
            this.$snotify.error(
              "The email you entered doesn't belong to an account. Please check your email and try again."
            )
          } else if (error.code === 'auth/wrong-password') {
            this.$snotify.error(
              'Sorry, your password was incorrect. Please double-check your password.'
            )
          }
        })
    },
    sendRessetLink() {
      if (this.ressetEmail !== 'inspireforhealthy@gmail.com') {
        this.isLoading = true
        this.$fireAuth
          .sendPasswordResetEmail(this.ressetEmail)
          .then(() => {
            //  console.log('sent');
            this.isLoading = false
            this.closeModal()
          })
          .catch((error) => {
            this.isLoading = false
            console.log('error: ', error)
          })
      }
    },
    ressetPass() {
      this.isActive = true
    },
    closeModal() {
      this.isActive = false
    }
  }
}
